<template>
  <v-snackbar
    :class="snackbar_data.color"
    class="my-snackbar"
    v-model="snackbar_data.show"
    top
    right
    :color="snackbar_data.snackbarColor"
  >
    {{ snackbar_data.message }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="updateSnacbarState">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  data() {
    return {
      localSnackbar: false,
      snackbar_data: {
        show: "",
        message: "",
        snackbarColor: "",
      },
    };
  },
  mounted() {
    this.$root.$on("snack_bar", (data) => {
      this.snackbar_data.show = data.show;
      this.snackbar_data.message = data.message;
      this.snackbar_data.snackbarColor = data.snackbarColor;
    });
  },
  methods: {
    updateSnacbarState: function () {
      this.snackbar_data.show = false;
    },
  },
  watch: {
    //assign snacbar prop to local snacbar data variable
    snackbar: function (newVal, oldVal) {
      this.localSnackbar = this.newVal;
    },
    localSnackbar: function () {
      if (this.localSnackbar == false) {
        this.$emit("update:snackbar", false);
      }
    },
  },
};
</script>

<style>
.my-snackbar {
  margin-top: 80px !important;
  z-index: 99999 !important;
}
</style>