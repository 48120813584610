<template>
  <div>
    <v-app>
    <NavbarLinks />
    <v-main>
      <router-view :key="routerKey"></router-view>
      <SnackbarNew/>
    </v-main>
    </v-app>
    <br>
    <br>
    <br>
    <Footer />
  </div>
</template>
<script>
import SnackbarNew from "@/components/includes/SnackbarNew";
import { sha256 } from "js-sha256";
export default {
  name: "app-layout",
  components: {
    NavbarLinks: () => import("@/components/includes/NavbarLinks"),
    Footer: () => import("@/components/includes/Footer"),
    SnackbarNew
  },
  data() {
    return {
    }
  },
  computed: {

    show_progress_loader() {
      return this.$store.state.custom.show_progress_loader;
    },
    routerKey() {
      return this.$store.state.custom.routerKey;
    },

  },
  mounted() {
      setTimeout(() => {
      this.bindMouseFlow();
    }, 5000);

    this.$store.commit("custom/set_loader", false);
    this.clear_logout_interval = setInterval(
      () =>
        localStorage.setItem(
          "logout_timer",
          (this.inActiveCounter -= 1).toString()
        ),
      1000
    );

    setInterval(() => {
      if (localStorage.getItem("logout_timer") < 0) {
        this.$store
          .dispatch("custom/user_logout")
          .then(() => {
            this.$store.commit("custom/resetState");
            this.$store.dispatch("auth/reset").then(() => {
              location.reload(true);
              return this.$router.push({ name: "login" });
            });
          })
          .catch(() => {});
      }
    }, 1000);
  },
  methods: {
        bindMouseFlow() {
      if (
        window.location.href.split("/")[2] === "backoffice.stafftimerapp.com"
      ) {
        //without PF company bind for ALL clients
        // Adds custom variable to mousefllow
        let ketData = sha256(window.location.href.split("/")[3]);
        let checkCompany = this.$store.state.pf.keysData.includes(ketData);
        if (checkCompany == false) {
          window._mfq = window._mfq || [];
          (function() {
            var mf = document.createElement("script");
            mf.type = "text/javascript";
            mf.defer = true;
            mf.src =
              "//cdn.mouseflow.com/projects/dcbe978e-93d1-42c6-b73a-0a7d6ca6c2c2.js";
            document.getElementsByTagName("head")[0].appendChild(mf);
          })();
          if (this.$store.state.auth.user) {
            window._mfq.push([
              "setVariable",
              "user",
              this.$store.state.auth.user.name,
            ]);
          }
          // Setting custom varibles for payment_gateway
          if(this.$store.state.custom.selected_company){
            if (this.$store.state.custom.selected_company.stripe_plan == null) {
            window._mfq.push([
              "setVariable",
              "status",
              "STA-TrialUser",
            ]);
          }
          else{
            window._mfq.push([
              "setVariable",
              "status",
              "STA-ActiveUser",
            ]);
           }
          }
        }
      }
    }
  }
};
</script>
